import React from 'react';
import {
  Edit,
  TextInput,
  SimpleForm,
  DisabledInput,
  BooleanInput,
  SaveButton,
  Toolbar,
  translate
} from 'react-admin';
import Button from '@material-ui/core/Button';

const ApnsEditToolbar = translate(({ onCancel, translate, ...props }) => (
  <Toolbar {...props}>
    <SaveButton />
    <Button onClick={onCancel}>{translate('ra.action.cancel')}</Button>
  </Toolbar>
));

const DevicesApnsEdit = ({ onCancel, ...props }) => (
  <Edit undoable={false} {...props}>
    <SimpleForm
      title=" "
      {...props}
      toolbar={<ApnsEditToolbar onCancel={onCancel} />}
    >
      <TextInput source="deviceToken" />
      <BooleanInput source="enabled" />
      <DisabledInput source="registeredAt" />
    </SimpleForm>
  </Edit>
);

export default DevicesApnsEdit;
