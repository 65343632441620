import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import { showNotification } from 'react-admin';
import swal from 'sweetalert';

class ClearMessageQueueButton extends Component {
  handleClick = () => {
    const { showNotification } = this.props;

    swal({
      title: 'Are you sure?',
      text: 'Once deleted, you will not be able to recover the data!',
      icon: 'warning',
      buttons: true,
      dangerMode: true
    }).then(willDelete => {
      if (willDelete) {
        fetch(process.env.REACT_APP_API_BASE_PATH + '/queues/purge', {
          method: 'DELETE',
          headers: new Headers({
            Authorization: 'Bearer ' + localStorage.getItem('token')
          })
        })
          .then(response => response.json())
          .then(data => {
            showNotification('app.messages.broker_purged', 'info', {
              messageArgs: {
                message: data.data.message,
                updated_notifications: data.data.updated_notifications
              }
            });
          })
          .catch(e => {
            showNotification('Error: Purge failed', 'error');
          });
      }
    });
  };

  render() {
    return (
      <Button
        color="primary"
        variant="contained"
        label="app.dashboard.actions.clear_button"
        onClick={this.handleClick}
      >
        {this.props.text}
      </Button>
    );
  }
}

ClearMessageQueueButton.propTypes = {
  showNotification: PropTypes.func,
  text: PropTypes.string
};

export default connect(
  null,
  {
    showNotification
  }
)(ClearMessageQueueButton);
