import React from 'react';

import {
  Create,
  FormTab,
  TabbedForm,
  TextInput,
  LongTextInput
} from 'react-admin';

const validateNotificationCreation = values => {
  const errors = {};

  if (!values.authenticationKey) {
    errors.authenticationKey = ['Insert Api Key'];
  }

  if (!values.installation_id) {
    errors.installation_id = ['Can not be empty.'];
  }

  if (!values.payload) {
    errors.payload = ['Please add json string.'];
  }

  if (values.payload && !isJsonString(values.payload)) {
    errors.payload = ['Payload is invalid.'];
  }

  return errors;
};

const isJsonString = str => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

const JsonExampleField = props => (
  <div>
    <h5>{props.headline}</h5>
    <pre>
      {JSON.stringify(
        {
          data: {
            alert_title: 'data.alert_title',
            alert_body: 'data.alert_body',
            datat: {
              title: '',
              body: ''
            }
          }
        },
        null,
        2
      )}
    </pre>
  </div>
);

const TestCenter = () => (
  <Create
    basePath="/"
    resource="notifications/published"
    location="notifications/published"
  >
    <TabbedForm validate={validateNotificationCreation}>
      <FormTab label="Notification">
        <TextInput source="authenticationKey" resettable />
        <TextInput source="installation_id" />
        <TextInput source="device_id" />
        <JsonExampleField headline={'Example Payload'} />
        <LongTextInput source="payload" resettable />
      </FormTab>
    </TabbedForm>
  </Create>
);
export default TestCenter;
