import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import { showNotification } from 'react-admin';
import { push } from 'react-router-redux';

class TestCenterButton extends Component {
  render() {
    return (
      <Button
        variant="contained"
        color="primary"
        label="Test Center"
        href="#/test-center"
      >
        {this.props.text}
      </Button>
    );
  }
}

TestCenterButton.propTypes = {
  push: PropTypes.func,
  showNotification: PropTypes.func
};

export default connect(
  null,
  {
    showNotification,
    push
  }
)(TestCenterButton);
