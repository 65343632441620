import { FcmList } from "./fcm.list";
import { FcmShow } from "./fcm.show";
import { FcmEdit } from "./fcm.edit";
import { CreateFcmPlatform } from "./fcm.create";

export default {
  name: "platforms/fcm",
  options: {label: 'FCM'},
  list: FcmList,
  show: FcmShow,
  edit: FcmEdit,
  create: CreateFcmPlatform
}