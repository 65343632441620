import React from 'react';
import {
  BooleanInput,
  Edit,
  LongTextInput,
  SimpleForm,
  TextInput
} from 'react-admin';

export const ApnsEdit = props => {
  return (
    <Edit undoable={false} {...props}>
      <SimpleForm redirect="show">
        <TextInput source="bundleId" />
        <BooleanInput source="enabled" />
        <BooleanInput source="develop" />
        <LongTextInput source="certificate.content" title="title" />
        <TextInput source="password" />
      </SimpleForm>
    </Edit>
  );
};
