import React from 'react';
import {
  AutocompleteInput,
  BooleanInput,
  Create,
  LongTextInput,
  ReferenceInput,
  SimpleForm,
  TextInput
} from 'react-admin';

export const ApplicationCreate = props => (
  <Create undoable="false" {...props}>
    <SimpleForm redirect={'list'}>
      <TextInput source="name" resettable />
      <LongTextInput source="serviceAccountData" resettable />
      <BooleanInput source="enabled" />
      <ReferenceInput
        label="Customers"
        source="id"
        reference="selectable-customers"
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
    </SimpleForm>
  </Create>
);
