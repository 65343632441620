import React from 'react';
/**
 * react-admin imports
 */
import { Admin, resolveBrowserLocale, Resource } from 'react-admin';
/**
 * clients
 */
import httpClient from './client/http.client';
/**
 * sagas
 */
import errorCreateSagas from './sagas/create-failure.saga';
import errorUpdateSagas from './sagas/update-failure.saga';
/**
 * providers
 */
import AuthenticationProvider from './providers/authentication.provider';
import RestProvider from './providers/rest-data.provider';
/**
 * view imports
 */
import { NotificationLoginPage } from './containers/login/login.page';
/**
 * i18n
 */
import germanMessages from 'ra-language-german';
import englishMessages from 'ra-language-english';
import { de, en } from './i18n/domainMessages';
/**
 * containers
 */
import Dashboard from './containers/dashboard/dashboard';
import Customers from './containers/customers';
import Applications from './containers/applications';
import ApnsPlatforms from './containers/platforms/apns';
import FcmPlatforms from './containers/platforms/fcm';
import DevicesApns from './containers/devices/apns';
import DevicesFcm from './containers/devices/fcm';
import Queues from './containers/notifications';
import { ErrorLogs, SuccessLogs } from './containers/logs';
import TestCenter from './containers/test-center';
import Reminders from './containers/reminders';
/**
 * theme
 */
import notificationTheme from './themes/notification.theme';
import CustomLayout from './themes/custom-layout';
/**
 * Routes (custom)
 */
import Routes from './config/routes';

/**
 * @type {{de: (({ra}&{resources})|*), en: {resources}}}
 */
const messages = {
  de: { ...germanMessages, ...de },
  en: { ...englishMessages, ...en }
};
/**
 * @param locale
 * @return {*}
 */
const i18nProvider = locale => messages[locale];

/**
 * @type {Function}
 */
const dataProvider = RestProvider(
  process.env.REACT_APP_API_BASE_PATH,
  httpClient
);

const App = () => (
  <Admin
    theme={notificationTheme}
    locale={resolveBrowserLocale()}
    i18nProvider={i18nProvider}
    customRoutes={Routes}
    appLayout={CustomLayout}
    loginPage={NotificationLoginPage}
    dashboard={Dashboard}
    authProvider={AuthenticationProvider}
    dataProvider={dataProvider}
    customSagas={[errorCreateSagas, errorUpdateSagas]}
  >
    <Resource {...Queues} />
    <Resource {...SuccessLogs} />
    <Resource {...ErrorLogs} />
    <Resource {...Customers} />
    <Resource name={'selectable-customers'} />
    <Resource {...Applications} />
    <Resource name={'selectable-applications'} />
    <Resource {...FcmPlatforms} />
    {/*TODO: add create route, handle update type*/}
    <Resource {...DevicesFcm} />
    <Resource {...ApnsPlatforms} />
    {/*TODO: add create route, handle update type*/}
    <Resource {...DevicesApns} />
    <Resource {...Reminders} />
    <Resource {...TestCenter} />
  </Admin>
);

export default App;
