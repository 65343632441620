export const fcmUpdate = (data) => {
  return {
    serverToken: data.serverToken,
    enabled: data.enabled,
  }
};

export const fcmCreate = (data) => {
  return {
    application: data.id,
    serverToken: data.serverToken,
    enabled: data.enabled,
  }
};