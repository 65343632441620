import { ApplicationList } from "./applications.list";
import { ApplicationEdit } from "./applications.edit";
import { ApplicationShow } from "./applications.show";
import { ApplicationCreate } from "./applications.create";
import AppIcon from '@material-ui/icons/Apps';

export default {
  name: "applications",
  list: ApplicationList,
  edit: ApplicationEdit,
  show: ApplicationShow,
  create: ApplicationCreate,
  icon: AppIcon
}