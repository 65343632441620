import React from 'react';
import {
  BooleanField,
  DateField,
  NumberField,
  Show,
  Tab,
  TabbedShowLayout,
  TextField
} from 'react-admin';
import { DownloadField } from '../../../components/download-field.component';

export const ApnsShow = props => {
  return (
    <Show {...props}>
      <TabbedShowLayout>
        <Tab label="APNS">
          <TextField source="bundleId" />
          <DownloadField source="certificate" text="Certificate" />
          <BooleanField source="enabled" />
          <BooleanField source="develop" />
          <NumberField source="deviceCount" />
          <NumberField source="totalNotifications" />
          <TextField source="password" />
          <DateField
            source="renewedAt"
            locales="de-DE"
            options={{
              weekday: 'long',
              year: 'numeric',
              month: 'long',
              day: 'numeric'
            }}
          />
          <DateField
            source="createdAt"
            locales="de-DE"
            options={{
              weekday: 'long',
              year: 'numeric',
              month: 'long',
              day: 'numeric'
            }}
          />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};
