import React from 'react';
import {
  Datagrid,
  DateField,
  DateInput,
  Filter,
  List,
  NumberField,
  ShowButton,
  TextField,
  TextInput
} from 'react-admin';
import moment from 'moment';

const DateFilter = props => {
  return (
    <Filter {...props}>
      <DateInput
        label="app.filter.requestTransmittedAt"
        source="requestTransmittedAt"
        options={{ format: 'YYYY-MM-DD' }}
        alwaysOn
      />
      <TextInput label="Search" source="applicationName" alwaysOn />
    </Filter>
  );
};

export const SuccessList = props => (
  <List
    {...props}
    filters={<DateFilter />}
    filterDefaultValues={{
      requestTransmittedAt: moment().format('YYYY-MM-DD')
    }}
    bulkActions={false}
    sort={false}
  >
    <Datagrid>
      <TextField source="applicationName" />
      <NumberField source="addressedDevices" />
      <TextField source="targetSystem" />
      <DateField
        source="transmittedAt"
        locales="de-DE"
        options={{
          weekday: 'long',
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit'
        }}
      />
      <ShowButton />
    </Datagrid>
  </List>
);
