import { CustomerList } from "./customers.list";
import { CustomerShow } from "./customers.show";
import { CustomerEdit } from "./customers.edit";
import { CustomerCreate } from "./customers.create";
import UserIcon from '@material-ui/icons/People';

export default {
  name: "customers",
  list: CustomerList,
  show: CustomerShow,
  edit: CustomerEdit,
  create: CustomerCreate,
  icon: UserIcon
}