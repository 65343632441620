import { createMuiTheme } from '@material-ui/core/styles';
import red from '@material-ui/core/colors/red';
import indigo from '@material-ui/core/colors/indigo';

const notificationTheme = createMuiTheme({
  palette: {
    type: 'light',
    primary: indigo,
    secondary: {
      main: '#3367d6'
    },
    error: red,
    contrastThreshold: 3,
    tonalOffset: 0.2
  },
  overrides: {
    MuiDrawer: {
      docked: {}
    },
    MuiButtonBase: {
      root: {}
    }
  }
});

export default notificationTheme;
