import React from 'react';
import {
  BooleanField,
  Datagrid,
  DateField,
  Filter,
  List,
  TextField,
  TextInput
} from 'react-admin';

const DeviceFilter = props => (
  <Filter {...props}>
    <TextInput
      label="InstallationId"
      source="installationId"
      placeholder="InstallationId"
      alwaysOn
      resettable
    />
    <TextInput
      label="RegistrationId"
      source="registrationId"
      placeholder="RegistrationId"
      alwaysOn
      resettable
    />
  </Filter>
);

const DevicesFcmList = props => {
  return (
    <div>
      <List
        {...props}
        filters={<DeviceFilter />}
        sort={{ field: 'registeredAt', order: 'DESC' }}
        bulkActions={false}
      >
        <Datagrid>
          <TextField source="applicationName" />
          <TextField source="installationId" />
          <TextField source="registrationId" />
          <BooleanField source="enabled" />
          <DateField
            source="registeredAt"
            locales="de-DE"
            options={{
              weekday: 'long',
              year: 'numeric',
              month: 'long',
              day: 'numeric',
              hour: '2-digit',
              minute: '2-digit',
              second: '2-digit'
            }}
          />
        </Datagrid>
      </List>
    </div>
  );
};

export default DevicesFcmList;
