import React from 'react';
import { TextField } from 'react-admin';

const QueueStateField = ({ record, ...rest }) => {
  let color;

  switch (record.queueState) {
    case 'failed':
      color = '#f15b5b';
      break;
    case 'processed':
      color = '#5ee25e';
      break;
    case 'queued':
      color = '#ecae3b';
      break;
    default:
      color = '';
      break;
  }

  return (
    <TextField
      style={{
        color: color,
        padding: '0.4em',
        textTransform: 'uppercase',
        fontWeight: 'bold'
      }}
      source="queueState"
      record={record}
      {...rest}
    />
  );
};

export default QueueStateField;
