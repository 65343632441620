import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_ERROR, AUTH_CHECK } from "react-admin";

export default (type, params) => {
  if (type === AUTH_LOGIN) {
    const { username, password } = params;
    const request = new Request(process.env.REACT_APP_AUTH_PATH, {
      method: "POST",
      body: JSON.stringify({ username, password }),
      headers: new Headers({
        "Content-Type": "application/json",
        Accept: "application/json"
      })
    });

    return fetch(request)
      .then(response => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then(response => {
        localStorage.setItem("token", response.token);
        localStorage.setItem("ttl", response.ttl);
      });
  }

  if (type === AUTH_LOGOUT) {
    localStorage.removeItem("token");
    localStorage.removeItem("ttl");
    return Promise.resolve();
  }

  if (type === AUTH_ERROR) {
    const status = params.status;
    if (status === 401 || status === 403) {
      localStorage.removeItem("token");
      localStorage.removeItem("ttl");
      return Promise.reject();
    }

    if (type === AUTH_CHECK) {
      return localStorage.getItem("token")
        ? Promise.resolve()
        : Promise.reject();
    }

    return Promise.resolve();
  }

  return Promise.resolve();
};
