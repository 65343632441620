import React from 'react';
import {
  BooleanField,
  Datagrid,
  DateField,
  EditButton,
  Filter,
  List,
  ShowButton,
  TextField,
  TextInput
} from 'react-admin';
import { DownloadField } from '../../../components/download-field.component';

export const ApnsList = props => (
  <List
    {...props}
    sort={{ field: 'createdAt', order: 'DESC' }}
    bulkActions={false}
    filters={<ApnsFilter />}
  >
    <Datagrid>
      <TextField source="applicationName" sortable={false} />
      <TextField source="bundleId" sortable={false} />
      <BooleanField source="enabled" />
      <BooleanField source="develop" sortable={false} />
      <DateField
        source="renewedAt"
        locales="de-DE"
        options={{
          weekday: 'long',
          year: 'numeric',
          month: 'long',
          day: 'numeric'
        }}
      />
      <DownloadField source="certificate" text="Certificate" sortable={false} />
      <DateField
        source="createdAt"
        locales="de-DE"
        options={{
          weekday: 'long',
          year: 'numeric',
          month: 'long',
          day: 'numeric'
        }}
      />
      <EditButton />
      <ShowButton />
    </Datagrid>
  </List>
);

const ApnsFilter = props => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
  </Filter>
);
