import React from 'react';
import {
  BooleanField,
  Datagrid,
  DateField,
  EditButton,
  Filter,
  List,
  ShowButton,
  TextField,
  TextInput
} from 'react-admin';

export const FcmList = props => (
  <List
    {...props}
    bulkActions={false}
    filters={<FCMFilter />}
    sort={{ field: 'createdAt', order: 'DESC' }}
  >
    <Datagrid>
      <TextField source="applicationName" sortable={false} />
      <BooleanField source="enabled" />
      <TextField source="serverToken" sortable={false} />
      <DateField
        source="createdAt"
        locales="de-DE"
        options={{
          weekday: 'long',
          year: 'numeric',
          month: 'long',
          day: 'numeric'
        }}
      />
      <EditButton />
      <ShowButton />
    </Datagrid>
  </List>
);

const FCMFilter = props => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
  </Filter>
);
