import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import compose from 'recompose/compose';
import { Drawer, withStyles } from '@material-ui/core';

import {
  BooleanField,
  CardActions,
  CreateButton,
  Datagrid,
  DateField,
  EditButton,
  Filter,
  List,
  TextField,
  TextInput
} from 'react-admin';

import { Route } from 'react-router';
import DevicesApnsCreate from './apns.create';
import DevicesApnsEdit from './apns.edit';

const styles = {
  drawerContent: {
    width: 600
  }
};

const ApnsDeviceListActions = ({ basePath }) => (
  <CardActions>
    <CreateButton basePath={basePath} />
  </CardActions>
);

const DeviceFilter = props => (
  <Filter {...props}>
    <TextInput label="Search" source="q" placeholder="DeviceToken" alwaysOn />
  </Filter>
);

class ApnsDevicesList extends React.Component {
  render() {
    const { push, classes, ...props } = this.props;
    return (
      <Fragment>
        <List
          {...props}
          actions={<ApnsDeviceListActions />}
          filters={<DeviceFilter />}
          sort={{ field: 'registeredAt', order: 'ASC' }}
          bulkActions={false}
        >
          <Datagrid rowClick="edit">
            <TextField source="applicationName" />
            <TextField source="deviceToken" />
            <TextField source="installationId" />
            <BooleanField source="enabled" />
            <DateField
              source="registeredAt"
              locales="de-DE"
              options={{
                weekday: 'long',
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit'
              }}
            />
            <EditButton />
          </Datagrid>
        </List>
        <Route path="/devices/apns/create">
          {({ match }) => {
            return (
              <Drawer open={!!match} anchor="right" onClose={this.handleClose}>
                <DevicesApnsCreate
                  className={classes.drawerContent}
                  onCancel={this.handleClose}
                  {...props}
                />
              </Drawer>
            );
          }}
        </Route>
        <Route path="/devices/apns/:id">
          {({ match }) => {
            const isMatch =
              match && match.params && match.params.id !== 'create';

            return (
              <Drawer
                open={!!isMatch}
                anchor="right"
                onClose={this.handleClose}
              >
                {isMatch ? (
                  <DevicesApnsEdit
                    className={classes.drawerContent}
                    id={isMatch ? match.params.id : null}
                    onCancel={this.handleClose}
                    {...props}
                  />
                ) : (
                  <div className={classes.drawerContent} />
                )}
              </Drawer>
            );
          }}
        </Route>
      </Fragment>
    );
  }

  handleClose = () => {
    this.props.push('/devices/apns');
  };
}

export default compose(
  connect(
    undefined,
    { push }
  ),
  withStyles(styles)
)(ApnsDevicesList);
