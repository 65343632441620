import React from 'react';
import { Datagrid, DateField, List, TextField } from 'react-admin';
import ActionField from '../../components/actionField';

const Pagination = () => {
  return null;
};

const ExpiredDateField = ({ source, locales, options, record }) => {
  return (
    <DateField
      source={source}
      style={{ color: record.expired ? 'red' : 'green' }}
      locales={locales}
      options={options}
      record={record}
    />
  );
};

export const ReminderList = props => {
  return (
    <List
      {...props}
      pagination={<Pagination />}
      bulkActions={false}
      exporter={false}
    >
      <Datagrid rowClick="edit">
        <TextField source="id" />
        <TextField source="organizationName" />
        <TextField source="commonName" />
        <ExpiredDateField
          source="validTo"
          locales="de-DE"
          options={{
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric'
          }}
        />
        <TextField source="serialNumber" />
        <ActionField />
      </Datagrid>
    </List>
  );
};
