export const notificationCreate = data => {
  let result = {
    data: {},
    installation_ids: [data.installation_id],
    device_ids: [data.device_id]
  };

  if (data.payload) {
    result.data = JSON.parse(data.payload);
  }

  return {
    authenticationKey: data.authenticationKey,
    payload: result
  };
};
