import React from 'react';
import {
  BooleanInput,
  Edit,
  LongTextInput,
  SimpleForm,
  TextInput
} from 'react-admin';

export const ApplicationEdit = props => (
  <Edit undoable="false" {...props}>
    <SimpleForm redirect={'list'}>
      <TextInput source="name" />
      <LongTextInput source="serviceAccountData" resettable />
      <BooleanInput source="enabled" />
    </SimpleForm>
  </Edit>
);
