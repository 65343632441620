import React from 'react';
import {
  AutocompleteInput,
  BooleanInput,
  Create,
  ReferenceInput,
  SimpleForm,
  TextInput
} from 'react-admin';

export const CreateFcmPlatform = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="serverToken" />
      <ReferenceInput
        label="Applications"
        source="id"
        reference="selectable-applications"
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
      <BooleanInput source="enabled" />
    </SimpleForm>
  </Create>
);
