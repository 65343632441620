import QueryString from 'query-string';
import {
  handleCreate,
  handleUpdate
} from './services/resource-handler.service';

import {
  CREATE,
  DELETE,
  DELETE_MANY,
  fetchUtils,
  GET_LIST,
  GET_MANY,
  GET_MANY_REFERENCE,
  GET_ONE,
  UPDATE,
  UPDATE_MANY
} from 'react-admin';

/**
 * Maps react-admin queries to a simple REST API
 *
 * The REST dialect is similar to the one of FakeRest
 * @see https://github.com/marmelab/FakeRest
 * @example
 * GET_LIST     => GET http://my.api.url/posts?sort=['title','ASC']&range=[0, 24]
 * GET_ONE      => GET http://my.api.url/posts/123
 * GET_MANY     => GET http://my.api.url/posts?filter={ids:[123,456,789]}
 * UPDATE       => PUT http://my.api.url/posts/123
 * CREATE       => POST http://my.api.url/posts
 * DELETE       => DELETE http://my.api.url/posts/123
 */
export default (apiUrl, httpClient = fetchUtils.fetchJson) => {
  /**
   * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
   * @param {String} resource Name of the resource to fetch, e.g. 'posts'
   * @param {Object} params The data request params, depending on the type
   * @returns {Object} { url, options } The HTTP request parameters
   */
  const convertDataRequestToHTTP = (type, resource, params) => {
    let url = '';
    const options = {};
    switch (type) {
      case GET_LIST: {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;

        const query = {
          page: page,
          max_per_page: perPage,
          sort: field,
          order: order
        };

        if (params.filter !== {}) {
          query.filter = JSON.stringify({
            ...params.filter,
            [params.target]: params.id
          });
        }

        const queryString = QueryString.stringify(query, {
          skipNull: true,
          skipEmptyString: true
        });

        url = `${apiUrl}/${resource}?${queryString}`;
        break;
      }
      case GET_ONE:
        url = `${apiUrl}/${resource}/${params.id}`;
        break;
      case GET_MANY: {
        url = `${apiUrl}/${resource}`;
        break;
      }
      case GET_MANY_REFERENCE: {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;

        const query = {
          page: page,
          max_per_page: perPage,
          sort: field,
          order: order
        };

        if (params.filter !== {}) {
          query.filter = JSON.stringify(params.filter);
        }

        const queryString = QueryString.stringify(query, {
          skipNull: true,
          skipEmptyString: true
        });

        url = `${apiUrl}/${resource}?${queryString}`;
        break;
      }
      case UPDATE:
        url = `${apiUrl}/${resource}/${params.id}`;

        let updateData = handleUpdate(resource, params.data);

        options.method = updateData instanceof FormData ? 'POST' : 'PATCH';

        if (updateData instanceof FormData) {
          options.headers = new Headers({});
        }

        options.body =
          updateData instanceof FormData
            ? updateData
            : JSON.stringify(updateData);
        break;
      case CREATE:
        url = `${apiUrl}/${resource}`;
        options.method = 'POST';
        let createData = handleCreate(resource, params.data);

        if (createData instanceof FormData) {
          options.headers = new Headers({});
        }

        if (resource === 'notifications/published') {
          url = `${process.env.REACT_APP_API_LEGACY_PATH}/${resource}`;
          createData = handleCreate(resource, params.data).payload;

          options.headers = new Headers({
            'api-key': params.data.authenticationKey
          });
        }

        options.body =
          createData instanceof FormData
            ? createData
            : JSON.stringify(createData);
        break;
      case DELETE:
        url = `${apiUrl}/${resource}/${params.id}`;
        options.method = 'DELETE';
        break;
      default:
        throw new Error(`Unsupported fetch action type ${type}`);
    }
    return { url, options };
  };

  /**
   * @param {Object} response HTTP response from fetch()
   * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
   * @param {String} resource Name of the resource to fetch, e.g. 'posts'
   * @param {Object} params The data request params, depending on the type
   * @returns {Object} Data response
   */
  const convertHTTPResponse = (response, type, resource, params) => {
    const { json } = response;

    switch (type) {
      case GET_LIST:
        if (json.pagination) {
          return {
            data: json.items,
            total: json.pagination.total_items,
            count: json.pagination.total_pages
          };
        }

        if (json.data) {
          return {
            data: json.data,
            total: json.data.length,
            count: json.data.length
          };
        }

        return {
          data: json,
          total: json.length,
          count: json.length
        };
      case GET_MANY_REFERENCE:
        if (json.pagination) {
          return {
            data: json.items,
            total: json.pagination.total_items,
            count: json.pagination.total_pages
          };
        }

        return {
          data: json.data,
          total: json.data.length,
          count: json.data.length
        };
      case CREATE:
        return { data: { ...params.data } };
      default:
        return { data: json };
    }
  };

  /**
   * @param {string} type Request type, e.g GET_LIST
   * @param {string} resource Resource name, e.g. "posts"
   * @param {Object} payload Request parameters. Depends on the request type
   * @returns {Promise} the Promise for a data response
   */
  return (type, resource, params) => {
    // simple-rest doesn't handle filters on UPDATE route, so we fallback to calling UPDATE n times instead
    if (type === UPDATE_MANY) {
      return Promise.all(
        params.ids.map(id =>
          httpClient(`${apiUrl}/${resource}/${id}`, {
            method: 'PUT',
            body: JSON.stringify(params.data)
          })
        )
      ).then(responses => ({
        data: responses.map(response => response.json)
      }));
    }
    // simple-rest doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
    if (type === DELETE_MANY) {
      return Promise.all(
        params.ids.map(id =>
          httpClient(`${apiUrl}/${resource}/${id}`, {
            method: 'DELETE'
          })
        )
      ).then(responses => ({
        data: responses.map(response => response.json)
      }));
    }

    const { url, options } = convertDataRequestToHTTP(type, resource, params);
    return httpClient(url, options).then(response =>
      convertHTTPResponse(response, type, resource, params)
    );
  };
};
