import React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  BooleanInput
} from "react-admin";

export const FcmEdit = props => (
  <Edit undoable={false} {...props}>
    <SimpleForm>
      <TextInput source="serverToken" />
      <BooleanInput source="enabled" />
    </SimpleForm>
  </Edit>
);
