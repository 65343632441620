import { customerCreate, customerUpdate } from '../types/customer.type';
import {
  applicationCreate,
  applicationUpdate
} from '../types/application.type';
import { fcmCreate, fcmUpdate } from '../types/fcm.type';
import { apnsCreate, apnsUpdate } from '../types/apns.type';
import { deviceUpdate } from '../types/device.type';
import { notificationCreate } from '../types/notification.type';

export const handleUpdate = (resource, data) => {
  switch (resource) {
    case 'customers':
      return customerUpdate(data);
    case 'applications':
      return applicationUpdate(data);
    case 'platforms/fcm':
      return fcmUpdate(data);
    case 'platforms/apns':
      return apnsUpdate(data);
    case 'devices/apns':
      return deviceUpdate(data);
    case 'devices/fcm':
      return deviceUpdate(data);
    default:
      return data;
  }
};

export const handleCreate = (resource, data) => {
  switch (resource) {
    case 'customers':
      return customerCreate(data);
    case 'applications':
      return applicationCreate(data);
    case 'platforms/apns':
      return apnsCreate(data);
    case 'platforms/fcm':
      return fcmCreate(data);
    case 'notifications/published':
      return notificationCreate(data);
    default:
      return data;
  }
};
