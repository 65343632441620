import { ApnsList } from "./apns.list";
import { ApnsShow } from "./apns.show";
import { ApnsEdit } from "./apns.edit";
import { CreateApnsPlatform } from "./apns.create";

export default {
  name: "platforms/apns",
  options: {label: 'Apns'},
  list: ApnsList,
  show: ApnsShow,
  edit: ApnsEdit,
  create: CreateApnsPlatform
}