import React from 'react';
import {
  CardActions,
  EditButton,
  ListButton,
  Show,
  Tab,
  TabbedShowLayout,
  TextField
} from 'react-admin';

const CustomerTitle = ({ record }) => {
  return <span>Customer {record ? `"${record.name}"` : ''}</span>;
};

export const CustomerShow = props => (
  <Show title={<CustomerTitle />} actions={<CustomerShowActions />} {...props}>
    <TabbedShowLayout>
      <Tab label="customers">
        <TextField source="id" />
        <TextField source="name" />
        <TextField source="apiKey" />
      </Tab>
    </TabbedShowLayout>
  </Show>
);

const cardActionStyle = {
  zIndex: 2,
  display: 'inline-block',
  float: 'right'
};

const CustomerShowActions = ({ basePath, data, resource }) => (
  <CardActions style={cardActionStyle}>
    <EditButton basePath={basePath} record={data} />
    <ListButton basePath={basePath} />
  </CardActions>
);
