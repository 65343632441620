import React from 'react';
import { saveAs } from 'file-saver';
import PropTypes from 'prop-types';

const handleClick = (e, fileName) => {
  e.preventDefault();
  fetch(e.target.href, {
    method: 'GET',
    headers: new Headers({
      Authorization: 'Bearer ' + localStorage.getItem('token'),
      'Content-Type': 'application/json'
    })
  })
    .then(response => response.blob())
    .then(blob => {
      saveAs(blob, fileName);
    })
    .catch(e => {
      console.error(e);
    });
};

export const DownloadField = props => {
  let { source, record, text } = props;
  return record[source] ? (
    <a
      href={record[source].url}
      onClick={e => handleClick(e, record[source].fileName)}
      download
    >
      {text}
    </a>
  ) : null;
};

DownloadField.propTypes = {
  text: PropTypes.string.isRequired
};
