import React from 'react';
import {
  DateField,
  NumberField,
  Show,
  Tab,
  TabbedShowLayout
} from 'react-admin';
import QueueStateField from '../../components/queueStateField';

const JsonField = ({ record = {} }) => (
  <pre>{JSON.stringify(record.payload, null, 2)}</pre>
);

JsonField.defaultProps = { label: 'Payload' };

export const NotificationsShow = props => (
  <Show {...props}>
    <TabbedShowLayout>
      <Tab label="queues">
        <QueueStateField source="queueState" />
        <h5>Payload</h5>
        <JsonField />
        <NumberField source="deviceCount" />
        <DateField
          source="receivedAt"
          locales="de-DE"
          options={{
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit'
          }}
        />
      </Tab>
    </TabbedShowLayout>
  </Show>
);
