import React from 'react';
import { SimpleForm, TextInput, Create, LongTextInput } from 'react-admin';
import JSONInput from 'react-json-editor-ajrm';

export const CustomerCreate = props => (
  <Create {...props}>
    <SimpleForm redirect="show">
      <TextInput source="name" resettable />
      <LongTextInput source="serviceAccountData" resettable />
    </SimpleForm>
  </Create>
);
