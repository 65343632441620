import React from 'react';

import {
  Show,
  TabbedShowLayout,
  TextField,
  BooleanField,
  Tab,
  NumberField,
  DateField,
  EditButton,
  CardActions,
  ListButton,
  RefreshButton
} from 'react-admin';

const CustomerTitle = ({ record }) => {
  return <span>Application {record ? `"${record.name}"` : ''}</span>;
};

export const ApplicationShow = props => (
  <Show
    title={<CustomerTitle />}
    actions={<ApplicationShowActions />}
    {...props}
  >
    <TabbedShowLayout>
      <Tab label="applications">
        <TextField source="name" />
        <BooleanField source="enabled" />
        <TextField source="authenticationKey" />
        <TextField source="customer.name" />
      </Tab>
      <Tab label="fcm platform">
        <TextField label="Server Token" source="fcm.serverToken" />
        <BooleanField label="Status" source="fcm.enabled" />
        <NumberField label="Registered Devices" source="fcm.deviceCount" />
      </Tab>
      <Tab label="apns platform">
        <TextField label="BundleId" source="apns.bundleId" />
        <TextField label="Certificate" source="apns.certificate" />
        <TextField label="Password" source="apns.password" />
        <BooleanField label="Status" source="apns.enabled" />
        <BooleanField label="Develop" source="apns.develop" />
        <NumberField label="Device Count" source="apns.deviceCount" />
        <DateField
          label="Renewed"
          source="apns.renewed"
          locales="de-DE"
          options={{
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit'
          }}
        />
        <DateField
          label="CreatedAt"
          source="apns.createdAt"
          locales="de-DE"
          options={{
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit'
          }}
        />
      </Tab>
    </TabbedShowLayout>
  </Show>
);

const cardActionStyle = {
  zIndex: 2,
  display: 'inline-block',
  float: 'right'
};

const ApplicationShowActions = ({ basePath, data, resource }) => (
  <CardActions style={cardActionStyle}>
    <EditButton basePath={basePath} record={data} />
    <ListButton basePath={basePath} />
    <RefreshButton basePath={basePath} record={data} />
  </CardActions>
);
