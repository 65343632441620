import React from 'react';
import {
  Datagrid,
  List,
  TextField,
  ShowButton,
  EditButton,
  Filter,
  TextInput
} from 'react-admin';

export const CustomerList = props => (
  <List
    {...props}
    sort={{ field: 'name', order: 'ASC' }}
    bulkActions={false}
    filters={<CustomerFilters />}
  >
    <Datagrid rowClick="show">
      <TextField source="name" />
      <TextField source="apiKey" />
      <EditButton />
      <ShowButton />
    </Datagrid>
  </List>
);

const CustomerFilters = props => (
  <Filter {...props}>
    <TextInput label="Search" source="name" alwaysOn resettable={true} />
    <TextInput label="Api Key" source="apiKey" alwaysOn resettable={true} />
  </Filter>
);
