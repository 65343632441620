import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { DateField, Loading, Query, translate } from 'react-admin';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import PropTypes from 'prop-types';

const styles = theme => ({
  root: {
    width: '100%',
    height: '50em',
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto',
    overflowY: 'auto'
  },
  table: {
    minWidth: 700
  },
  chip: {
    margin: theme.spacing.unit
  }
});

const LogList = props => {
  const { classes, translate } = props;
  return (
    <Query
      type="GET_LIST"
      resource="logs/error"
      payload={{
        pagination: { page: 1, perPage: 10 },
        sort: { field: 'occurredAt', order: 'DESC' }
      }}
    >
      {({ data, total, loading, error }) => {
        if (loading) {
          return <Loading />;
        }
        if (error) {
          return <p>ERROR</p>;
        }
        return (
          <div className={classes.root}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>
                    {translate('app.dashboard.logs.table.application')}
                  </TableCell>
                  <TableCell>
                    {translate('app.dashboard.logs.table.status_code')}
                  </TableCell>
                  <TableCell>
                    {translate('app.dashboard.logs.table.message')}
                  </TableCell>
                  <TableCell>
                    {translate('app.dashboard.logs.table.occurred_at')}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>{row.applicationName}</TableCell>
                    <TableCell>{row.statusCode}</TableCell>
                    <TableCell>{row.message}</TableCell>
                    <TableCell>
                      <DateField
                        record={row}
                        source="occurredAt"
                        locales="de-DE"
                        options={{
                          weekday: 'long',
                          year: 'numeric',
                          month: 'long',
                          day: 'numeric'
                        }}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        );
      }}
    </Query>
  );
};

LogList.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(translate(LogList));
