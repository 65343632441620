import React from 'react';
import {
  Edit,
  SimpleForm,
  TextInput,
  DisabledInput,
  CardActions,
  ShowButton,
  LongTextInput
} from 'react-admin';
import JSONInput from 'react-json-editor-ajrm';

const CustomerTitle = ({ record }) => {
  return <span>Customer {record ? `"${record.name}"` : ''}</span>;
};

export const CustomerEdit = props => (
  <Edit
    undoable={false}
    title={<CustomerTitle />}
    actions={<CustomerEditActions />}
    {...props}
  >
    <SimpleForm>
      <TextInput source="name" resettable />
      <LongTextInput source="serviceAccountData" resettable />
      <DisabledInput source="apiKey" />
    </SimpleForm>
  </Edit>
);

const CustomerEditActions = ({ basePath, data, resource }) => (
  <CardActions>
    <ShowButton basePath={basePath} record={data} />
    {/* Add your custom actions */}
  </CardActions>
);
