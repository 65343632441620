export const customerUpdate = data => {
  return {
    name: data.name,
    serviceAccountData: data.serviceAccountData
  };
};

export const customerCreate = data => {
  return {
    name: data.name,
    serviceAccountData: data.serviceAccountData
  };
};
