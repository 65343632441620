import React from 'react';
import {
  DateField,
  NumberField,
  Show,
  Tab,
  TabbedShowLayout,
  TextField
} from 'react-admin';

const JsonField = ({ record = {} }) => (
  <pre>{JSON.stringify(record.notification, null, 2)}</pre>
);

JsonField.defaultProps = { label: 'Payload' };

export const SuccessShow = props => (
  <Show {...props}>
    <TabbedShowLayout>
      <Tab label="queues">
        <TextField source="targetSystem" />
        <NumberField source="addressedDevices" />
        <h5>Payload</h5>
        <JsonField />
        <DateField
          source="transmittedAt"
          locales="de-DE"
          options={{
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit'
          }}
        />
      </Tab>
    </TabbedShowLayout>
  </Show>
);
