/**
 * Special saga that react on create failures and return
 * server side errors to form component
 */
import { CRUD_CREATE_FAILURE } from 'react-admin';

import { stopSubmit } from 'redux-form';
import { put, takeEvery } from 'redux-saga/effects';

export default function* errorCreateSagas() {
  yield takeEvery(CRUD_CREATE_FAILURE, crudCreateFailure);
}

export function* crudCreateFailure(action) {
  yield put(
    stopSubmit('record-form', action.payload !== null ? action.payload : [])
  );
}
