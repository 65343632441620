export const apnsUpdate = data => {
  let formData = new FormData();

  formData.append('bundleId', data.bundleId);
  formData.append('certificate', data.certificate.content);

  if (data.enabled === true) {
    formData.append('enabled', data.enabled);
  }

  if (data.develop === true) {
    formData.append('develop', data.develop);
  }

  formData.append('password', data.password);

  return formData;
};

export const apnsCreate = data => {
  let formData = new FormData();

  if (Object.entries(data).length === 0) {
    return data;
  }

  formData.append('application', data.id);
  formData.append('bundleId', data.bundleId);
  formData.append('enabled', data.enabled);
  formData.append('develop', data.develop);
  formData.append('password', data.password);
  formData.append('certificate', data.certificate);

  return formData;
};
