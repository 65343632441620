import React from 'react';
import {
  Datagrid,
  DateField,
  DateInput,
  Filter,
  List,
  NumberField,
  TextField,
  TextInput
} from 'react-admin';
import moment from 'moment';

const ErrorLogFilter = props => (
  <Filter {...props}>
    <TextInput
      label="app.filter.applicationName"
      source="applicationName"
      alwaysOn
      resettable
    />
    <TextInput
      label="app.filter.statusCode"
      source="statusCode"
      alwaysOn
      resettable
    />
    <DateInput
      label="app.filter.occurredAt"
      source="occurredAt"
      options={{ format: 'YYYY-MM-DD' }}
      alwaysOn
      resettable
    />
  </Filter>
);

export const LogsErrorList = props => (
  <List
    {...props}
    sort={{ field: 'occurredAt', order: 'DESC' }}
    filters={<ErrorLogFilter />}
    filterDefaultValues={{
      occurredAt: moment().format('YYYY-MM-DD')
    }}
    bulkActions={false}
  >
    <Datagrid>
      <TextField source="applicationName" />
      <NumberField source="statusCode" />
      <TextField source="message" />
      <DateField
        source="occurredAt"
        locales="de-DE"
        options={{
          weekday: 'long',
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit'
        }}
        showTime
      />
    </Datagrid>
  </List>
);
