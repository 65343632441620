/**
 * Special saga that react on create failures and return
 * server side errors to form component
 */
import {CRUD_UPDATE_FAILURE} from 'react-admin';

import {stopSubmit} from 'redux-form';
import {put, takeEvery} from 'redux-saga/effects';

export default function* errorUpdateSagas() {
  yield takeEvery(CRUD_UPDATE_FAILURE, crudUpdateFailure);
}

export function* crudUpdateFailure(action) {
  yield put(stopSubmit('record-form', action.payload.errors));
}