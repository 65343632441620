import React from 'react';
import { Title, translate } from 'react-admin';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import LogList from '../../components/log-message.widget';
import ClearMessageQueueButton from '../../components/clear-message-queue.button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import TestCenterButton from '../../components/test-center.button';

const Dashboard = ({ translate }) => {
  return (
    <div>
      <Title title="Dashboard" />
      <Grid container spacing={16}>
        <Grid item xs={6}>
          <Card>
            <CardHeader title={translate('app.dashboard.actions.title')} />
            <CardContent>
              <List component="nav">
                <ListItem>
                  <ListItemText
                    primary={translate('app.dashboard.actions.clear_broker')}
                  />
                  <ClearMessageQueueButton
                    text={translate('app.dashboard.actions.clear_button')}
                  />
                </ListItem>
                <Divider />
                <Divider light />
                <ListItem>
                  <ListItemText
                    primary={translate(
                      'app.dashboard.actions.test_notifications'
                    )}
                  />
                  <TestCenterButton
                    text={translate('app.dashboard.actions.test_center_button')}
                  />
                </ListItem>
              </List>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={6}>
          <Card>
            <CardHeader title={translate('app.dashboard.logs.title')} />
            <CardContent>
              <LogList />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};
export default translate(Dashboard);
