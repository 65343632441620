import React, { Component } from 'react';
import Chip from '@material-ui/core/Chip';

class ApiState extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: []
    };
  }

  componentWillMount() {
    fetch(process.env.REACT_APP_API_HEALTH_PATH)
      .then(response => {
        return response.json();
      })
      .then(data => {
        this.setState({
          message: data.data
        });
      })
      .catch(e => {
        this.setState({
          message: 'Webservice not running'
        });
      });
  }

  render() {
    return <Chip label={this.state.message} color="primary" />;
  }
}

export default ApiState;
