export const de = {
  resources: {
    notifications: {
      name: 'Warteschlange',
      fields: {
        deviceCount: 'Anzahl der Geäte',
        receivedAt: 'Empfangen am',
        queueState: 'Status in der Warteschlange'
      }
    },
    customers: {
      name: 'Kunden',
      fields: {
        name: 'Kunde',
        apiKey: 'Authentifizierungs Schlüssel'
      }
    },
    applications: {
      name: 'Kunden Apps',
      fields: {
        name: 'App Name',
        enabled: 'Freigegeben',
        authenticationKey: 'Schlüssel für die Authentifizierung',
        'customer.name': 'Kunde'
      }
    },
    'platforms/fcm': {
      name: 'FCM Platformen',
      fields: {
        'application.data.name': 'Name',
        enabled: 'Freigegeben',
        deviceCount: 'Registrierte Geräte',
        serverToken: 'FCM Server Token'
      }
    },
    'platforms/apns': {
      name: 'APNS Platformen',
      fields: {
        'application.data.name': 'Name',
        enabled: 'Freigegeben',
        develop: 'Entwicklung',
        deviceCount: 'Registrierte Geräte',
        certificate: {
          file: {
            url: 'Zertifikat'
          },
          content: 'Inhalt des Zertifikats (.pem)'
        },
        password: 'Passwort',
        bundleId: 'iOS Bundle ID',
        renewedAt: 'Erneuert am',
        createdAt: 'Erstellt am',
        totalNotifications: 'Nachrichten (insgesamt)'
      }
    },
    'devices/fcm': {
      name: 'FCM Geräte',
      fields: {
        applicationName: 'App',
        enabled: 'Aktiv',
        deactivated: 'Deaktiviert',
        registeredAt: 'Registrierung'
      }
    },
    'devices/apns': {
      name: 'APNS Geräte',
      fields: {
        applicationName: 'App',
        deviceToken: 'Device',
        enabled: 'Aktiv',
        deactivated: 'Deaktiviert',
        registeredAt: 'Registrierung'
      }
    },
    'logs/error': {
      name: 'Fehler',
      fields: {
        applicationName: 'Anwendung',
        statusCode: 'Fehlercode',
        message: 'Nachricht',
        occurredAt: 'Aufgetreten am'
      }
    },
    'logs/success': {
      name: 'Versendete Nachrichten',
      fields: {
        applicationName: 'Anwendung',
        addressedDevices: 'Anzahl der adressiert Geräte',
        targetSystem: 'Ziel Service',
        transmittedAt: 'Versendet um'
      }
    },
    'stats/customer': {
      name: 'Statistiken',
      fields: {
        customer: 'Kunde',
        application: 'Anwendung',
        month: 'Monat',
        year: 'Jahr',
        totalAddressedDevices: 'Adressierte Geräte',
        totalNotificationNumber: 'Nachrichten Anzahl',
        platform: 'Plattform'
      }
    },
    reminders: {
      name: 'Erinnerungen',
      fields: {
        id: 'Dateiname',
        commonName: 'Name',
        organizationName: 'Organisation',
        validTo: 'Gültig bis',
        serialNumber: 'Seriennummer'
      }
    },
    'queues/stats': {
      name: 'Nachrichten Status'
    }
  },
  app: {
    dashboard: {
      actions: {
        title: 'Wartung & Testing',
        clear_broker: 'Message Broker DB leeren',
        clear_button: 'Leeren',
        download_logs: 'Server Logs herunterladen (ZIP)',
        download_button: 'Herunterladen',
        clear_server_logs: 'Server Logs leeren',
        clear_logs_button: 'Logs leeren',
        test_notifications: 'Test Nachrichten versenden',
        test_center_button: 'Zum Test Center'
      },
      logs: {
        title: 'Zuletzt empfangene Protokollnachrichten',
        table: {
          application: 'App',
          status_code: 'Status Code',
          message: 'Meldung',
          occurred_at: 'Aufgetreten am'
        }
      },
      'devices/fcm': {
        title: 'FCM Geräte pro Anwendung',
        table: {
          applicationName: 'App',
          active: 'Aktiv',
          inactive: 'Inaktiv',
          application: 'Anwendung'
        }
      },
      'devices/apns': {
        title: 'APNS Geräte pro Anwendung',
        table: {
          active: 'Aktiv',
          inactive: 'Inaktiv',
          application: 'Anwendung'
        }
      },
      fcm_queue_states: {
        title: 'Warteschlange FCM',
        subheader:
          'Anzahl der Nachrichten pro Anwendung gruppiert nach Status der letzten 7 Tage.',
        table: {
          count: 'Anzahl',
          name: 'Anwendung',
          status: 'Status'
        }
      },
      apns_queue_states: {
        title: 'Warteschlange APNS',
        subheader:
          'Anzahl der Nachrichten pro Anwendung gruppiert nach Status der letzten 7 Tage.',
        table: {
          count: 'Anzahl',
          name: 'Anwendung',
          status: 'Status'
        }
      }
    },
    messages: {
      broker_purged:
        '%{message} Updated notification packages %{updated_notifications}'
    },
    filter: {
      receivedAt: 'Empfangen am',
      requestTransmittedAt: 'Versendet am',
      combined: 'Statistik (summiert)',
      statusCode: 'Fehlercode',
      applicationName: 'App',
      occurredAt: 'Aufgetreten am'
    }
  }
};

export const en = {
  resources: {
    'queues/list': {
      name: 'Queue',
      fields: {
        deviceCount: 'Number of devices',
        receivedAt: 'Received at',
        queueState: 'state inside the queue'
      }
    },
    customers: {
      name: 'Customers',
      fields: {
        name: 'Customer',
        apiKey: 'Authentication Key'
      }
    },
    applications: {
      name: 'Applications',
      fields: {
        name: 'Name',
        enabled: 'Enabled',
        authenticationKey: 'Authentication Key',
        'customer.name': 'Customer'
      }
    },
    'platforms/fcm': {
      name: 'FCM Platforms',
      fields: {
        enabled: 'Enbled',
        deviceCount: 'Device Count',
        serverToken: 'FCM Server Token'
      }
    },
    'platforms/apns': {
      name: 'APNS Platforms',
      fields: {
        enabled: 'Enabled',
        develop: 'Develop',
        deviceCount: 'Device Count',
        certificate: {
          file: {
            url: 'Certificate'
          }
        },
        password: 'Password',
        bundleId: 'iOS Bundle ID',
        renewed: 'Renewed at',
        createdAt: 'Created at'
      }
    },
    'devices/fcm': {
      name: 'FCM Devices'
    },
    'devices/apns': {
      name: 'APNS Devices'
    },
    'logs/error': {
      name: 'Errors',
      fields: {
        applicationName: 'Application',
        statusCode: 'Statuscode',
        message: 'Message',
        occurredAt: 'Occurred at'
      }
    },
    'logs/success': {
      name: 'Successfully shipped'
    },
    'stats/customer': {
      name: 'Stats',
      fields: {
        customer: 'Customer',
        application: 'Application',
        month: 'Month',
        year: 'Year'
      }
    },
    reminders: {
      name: 'Reminders',
      fields: {
        id: 'Filename',
        'subject.common_name': 'Info',
        validTo: 'Valid to',
        serialNumber: 'Serialnumber'
      }
    }
  },
  app: {
    dashboard: {
      actions: {
        title: 'Service & Testing',
        clear_broker: 'Clear Broker',
        clear_button: 'clear',
        download_logs: 'Download server logs as zip',
        download_button: 'Download',
        clear_server_logs: 'Clear Server Logs',
        clear_logs_button: 'Clear logs',
        test_notifications: 'Send Test Notifications',
        test_center_button: 'Test Center'
      },
      logs: {
        title: 'Last received log messages',
        table: {
          application: 'Application',
          status_code: 'Status Code',
          message: 'Message',
          occurred_at: 'Occurred At'
        }
      },
      fcm_devices: {
        title: 'FCM Devices per Application',
        table: {
          active: 'Active',
          inactive: 'Inactive',
          application: 'Application'
        }
      },
      apns_devices: {
        title: 'APNS Devices per Application',
        table: {
          active: 'Active',
          inactive: 'Inactive',
          application: 'Application'
        }
      },
      fcm_queue_states: {
        title: 'Queue States FCM',
        subheader:
          'Number of notications per application grouped by state of the last seven days.',
        table: {
          count: 'Number',
          name: 'Application',
          status: 'State'
        }
      },
      apns_queue_states: {
        title: 'Queue States APNS',
        subheader:
          'Number of notications per application grouped by state of the last seven days.',
        table: {
          count: 'Number',
          name: 'Application',
          status: 'State'
        }
      }
    },
    messages: {
      broker_purged:
        '%{message} Updated notification packages %{updated_notifications}'
    },
    filter: {
      receivedAt: 'Received at',
      requestTransmittedAt: 'Transmitted at'
    }
  }
};
