import React from 'react';
import {
  Show,
  TextField,
  BooleanField,
  NumberField,
  DateField,
  TabbedShowLayout,
  Tab
} from 'react-admin';

export const FcmShow = props => (
  <Show {...props}>
    <TabbedShowLayout>
      <Tab label="FCM">
        <TextField source="serverToken" />
        <BooleanField source="enabled" />
        <NumberField source="deviceCount" />
        <NumberField source="totalNotifications" />
        <DateField
          source="createdAt"
          locales="de-DE"
          options={{
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric'
          }}
        />
      </Tab>
    </TabbedShowLayout>
  </Show>
);
