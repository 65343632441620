import { LogsErrorList } from './error.list';
import { SuccessList } from './success.list';
import BugReport from '@material-ui/icons/BugReport';
import Done from '@material-ui/icons/Done';
import { SuccessShow } from './success.show';

export const ErrorLogs = {
  name: 'logs/error',
  options: { label: 'Error Logs' },
  list: LogsErrorList,
  icon: BugReport
};

export const SuccessLogs = {
  name: 'logs/success',
  options: { label: 'Success Logs' },
  list: SuccessList,
  show: SuccessShow,
  icon: Done
};
