import React from 'react';
import { AppBar } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import ApiState from '../components/api-state.component';

const styles = {
  title: {
    flex: 1,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  },
  spacer: {
    flex: 1
  }
};

const CustomAppBar = withStyles(styles)(({ classes, ...props }) => {
  return (
    <AppBar {...props}>
      <Typography
        variant="title"
        color="inherit"
        className={classes.title}
        id="react-admin-title"
      />
      <span className={classes.spacer} />
      <ApiState />
    </AppBar>
  );
});

export default CustomAppBar;
