export const applicationUpdate = data => {
  return {
    name: data.name,
    serviceAccountData: data.serviceAccountData,
    enabled: data.enabled
  };
};

export const applicationCreate = data => {
  return {
    customer: data.id,
    name: data.name,
    serviceAccountData: data.serviceAccountData,
    enabled: data.enabled
  };
};
