import React from "react";
import {
  BooleanInput,
  Create,
  SimpleForm,
  TextInput,
  Toolbar,
  SaveButton,
  translate
} from "react-admin";
import Button from '@material-ui/core/Button';

const DeviceCreateToolbar = translate(({ onCancel, translate, ...props }) => (
  <Toolbar {...props}>
    <SaveButton />
    <Button onClick={onCancel}>{translate('ra.action.cancel')}</Button>
  </Toolbar>
));

const DevicesApnsCreate = ({ onCancel, ...props }) => (
  <Create title=" " {...props}>
    <SimpleForm toolbar={<DeviceCreateToolbar onCancel={onCancel} />}>
      <TextInput source="deviceToken" />
      <BooleanInput source="enabled" />
      <BooleanInput source="deactivated" />
    </SimpleForm>
  </Create>
);

export default DevicesApnsCreate;