import React from 'react';
import {
  BooleanField,
  Datagrid,
  EditButton,
  Filter,
  List,
  ShowButton,
  TextField,
  TextInput
} from 'react-admin';

export const ApplicationList = props => (
  <List
    {...props}
    filters={<ApplicationFilter />}
    sort={{ field: 'name', order: 'ASC' }}
    bulkActions={false}
  >
    <Datagrid>
      <TextField source="name" />
      <TextField source="customer.name" />
      <BooleanField source="enabled" />
      <TextField source="authenticationKey" />
      <EditButton />
      <ShowButton />
    </Datagrid>
  </List>
);

const ApplicationFilter = props => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
    <TextInput label="Authentication Key" source="authenticationKey" alwaysOn />
  </Filter>
);
