import React from 'react';
import {
  AutocompleteInput,
  BooleanField,
  Datagrid,
  DateField,
  DateInput,
  Filter,
  List,
  NumberField,
  ShowButton,
  TextField
} from 'react-admin';
import QueueStateField from '../../components/queueStateField';
import moment from 'moment';

const ListFilter = props => {
  return (
    <Filter {...props}>
      <DateInput
        label="app.filter.receivedAt"
        source="receivedAt"
        options={{ format: 'YYYY-MM-DD' }}
        alwaysOn
      />
    </Filter>
  );
};

export const NotificationsList = props => {
  return (
    <List
      {...props}
      sort={{ field: 'receivedAt', order: 'DESC' }}
      filters={<ListFilter />}
      filterDefaultValues={{ receivedAt: moment().format('YYYY-MM-DD') }}
      bulkActions={false}
    >
      <Datagrid rowClick="edit">
        <TextField source="applicationName" />
        <NumberField source="deviceCount" />
        <BooleanField source="apns" />
        <BooleanField source="fcm" />
        <DateField
          source="receivedAt"
          locales="de-DE"
          options={{
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit'
          }}
        />
        <QueueStateField source="queueState" />
        <ShowButton />
      </Datagrid>
    </List>
  );
};
